<template>
  <div
    class="headline-field step-field"
    :class="[
      {'step-field_focus': fieldFocus},
      {'step-field_render': !stepsField},
      {'step-field_error': errorStatus}
    ]"
  >
    <textInput
      v-if="stepsField"
      :typeInput="'text'"
      :autofocus="true"
      :stepsField="stepsField"
      :value.sync="content"
      :placeholderText="headlinePlaceholder || $t('elements.heading')"
      @inputFocusStatus="changeInputFocus"
    >
    </textInput>
    <div
      v-else
      class="step-field__render"
    >
      {{ content || headlinePlaceholder }}
    </div>
    <transition name="fade-validation">
      <span
        v-if="errorStatus"
        class="validation"
      >
        {{ validationErrorMessage }}
      </span>
    </transition>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import validationErrorMessage from '@/locales/validationErrorMessage';
import { findIndex, get } from 'lodash';
import NEW_HEADLINE from '@/preset/elements/newHeadline';

export default {
  name: 'Headline',
  components: {
    textInput,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    stepsField: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: 'ru',
    },
    fieldsError: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fieldFocus: false,
    };
  },
  computed: {
    errorStatus() {
      return this.fieldsError.includes(this.item.fieldId);
    },
    validationErrorMessage() {
      return validationErrorMessage[this.lang].inputRequired;
    },
    contentHeadline: {
      get() {
        return this.item;
      },
      set(data) {
        this.$emit('update:item', data);
      },
    },
    content: {
      get() {
        return this.contentHeadline.value;
      },
      set(data) {
        this.contentHeadline.value = data;
      },
    },
    headlinePlaceholder() {
      const headlinePlaceholder = get(this.contentHeadline, `placeholder.value.${this.lang}`);
      if (headlinePlaceholder) {
        return headlinePlaceholder;
      }
      const headline = NEW_HEADLINE();
      return get(headline, `placeholder.value.${this.lang}`);
    },
  },
  methods: {
    changeInputFocus(e) {
      this.fieldFocus = e;
      if (this.stepsField && e && this.errorStatus) {
        const indexCurrentField = findIndex(this.fieldsError, (field) => field === this.item.fieldId);
        this.fieldsError.splice(indexCurrentField, 1);
      }
    },
  },
};
</script>

<style lang="scss">
  @import "../../sass/_variables.scss";
</style>
